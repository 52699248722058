<template>
  <form ref="form" class="flex scroll-m-20 flex-col gap-4" @submit.prevent>
    <h5 class="text-subhead-4 xs:hidden">Create activity</h5>
    <UiInputTextField
      v-model="activity.title"
      name="Name"
      placeholder="Add activity name"
      compact
      :error="useGetFieldErrors(v$, ['title'])"
      @update:model-value="emits('update-title-on-calendar')"
    />
    <div id="activity-types-container">
      <div id="activity-types" class="mb-2 flex w-fit flex-row items-center gap-2">
        <UiTagPrimary
          v-for="(eventType, index) in mainCalendarTypes"
          :id="`create-activity-type-${eventType.id}`"
          :key="index"
          right-icon=""
          :style="{
            backgroundColor:
              activity.type_id === eventType.id
                ? CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(activity.type?.code)?.background
                : 'transparent',
          }"
          @click="updateType(eventType)"
        >
          <div class="text-subhead-3 text-black-100">
            {{ eventType.name }}
          </div>
        </UiTagPrimary>
      </div>
      <div
        v-if="
          useHasPermissions([PERMISSIONS.CREATE_SHARED_ACTIVITY]) &&
          activity.type?.code === CALENDAR_ACTIVITY_TYPES.MEETING
        "
        class="flex flex-col gap-4"
      >
        <UiInputCheckbox
          v-model="isForAnotherUser"
          name="isForAnotherUser"
          label="Create activity for another user"
          @update:model-value="updateUser"
        />
        <div v-if="isForAnotherUser" class="flex flex-row items-center gap-4">
          <UiIcon name="user" class="text-black-70" />
          <UiInputSelect
            ref="agentSelect"
            v-model="activity.user_id"
            name="agent"
            :items="usersItems"
            placeholder="Select User"
            compact
            @update:model-value="addUserObject"
          />
        </div>
      </div>
    </div>
    <hr class="border-t border-solid border-black-10" />
    <ActivityCreateDateTimeRangePicker
      :start="activity.start"
      :end="activity.end"
      :all-day="allDay"
      :error="useGetFieldErrors(v$, ['start', 'end'])"
      @update:model-value="updateActivityTime"
    ></ActivityCreateDateTimeRangePicker>
    <UiInputCheckbox
      v-if="isTaskType"
      v-model="allDay"
      label="All day"
      class="ml-10"
      name="all_day"
      border-color-class="border-black-30"
    />
    <transition name="fade" mode="out-in">
      <keep-alive>
        <component
          :is="currentComponent"
          v-model="activity"
          :vuelidate="v$"
          :selected-lead-email
          @add-lead-email="addLeadEmail"
          @update:model-value="$emit('update:modelValue', $event)"
        ></component>
      </keep-alive>
    </transition>
    <div class="flex flex-row items-start gap-4">
      <div class="mt-2.5 w-6">
        <UiIcon name="text-align-left" class="text-black-70" />
      </div>
      <UiInputTextarea v-model="activity.comment" name="Description" placeholder="Description" compact ghost />
    </div>
    <template v-if="activityToEdit || globalCalendarEnabled || (globalSmsEnabled && !isTaskType)">
      <hr class="border-t border-solid border-black-10" />
      <UiExpansionPanel
        v-model="reminderPanel"
        title="Reminders"
        class="relative z-10 pr-10"
        @update:model-value="interactedWithReminders = true"
      >
        <Transition name="fade">
          <div
            v-if="highlightReminders"
            class="text-body-2 absolute -left-4 top-[-52px] w-[calc(100%+32px)] rounded-xl border border-solid border-primary-20 bg-primary-05 p-2.5 shadow"
          >
            Lead will get sms notification about the upcoming activity
          </div>
        </Transition>
        <Transition name="fade">
          <div
            v-if="highlightReminders"
            class="absolute -left-4 -top-2 z-[-1] h-[calc(100%+16px)] w-[calc(100%+32px)] rounded-xl bg-primary-05"
          ></div>
        </Transition>
        <div class="flex flex-col gap-3">
          <div v-if="!isTaskType && (globalSmsEnabled || activityToEdit)" class="flex flex-col gap-1">
            <div class="text-body-2 flex flex-col gap-2 xs:flex-row">
              <UiInputCheckbox v-model="activity.sms_checkbox" name="remind_sms" class="flex items-center gap-x-2">
                <template #label>
                  <p class="flex items-center gap-x-2">Send sms to lead in</p>
                </template>
              </UiInputCheckbox>
              <span v-if="activity.sms_checkbox" class="flex items-center gap-x-2">
                <UiInputTextField
                  v-model="activity.sms"
                  name="sms_delay"
                  type="number"
                  placeholder="60"
                  compact
                  class="max-w-[100px]"
                  inline-error
                  :error="useGetFieldErrors(v$, ['sms'])"
                />
                minutes</span
              >
            </div>
            <p v-if="activity.sms_checkbox" class="text-caption-2 text-black-80">
              You may change language for sms reminder from the list of available in the CRM.
            </p>
            <div v-if="activity.sms_checkbox" class="flex flex-col gap-3 xs:flex-row">
              <UiInputTextField v-model="leadLanguage" compact disabled name="lead_language" label="Lead language" />
              <UiInputSelect
                v-model="activity.language_id"
                :items="templateLanguages"
                compact
                name="sms_language"
                label="SMS language"
                placeholder="Choose the language"
                :error="useGetFieldErrors(v$, ['language_id'])"
              />
            </div>
          </div>

          <div class="text-body-2 flex flex-col gap-2 xs:flex-row">
            <UiInputCheckbox
              v-if="globalCalendarEnabled || activityToEdit"
              v-model="activity.calendar_checkbox"
              name="remind_calendar"
            >
              <template #label>
                <p class="flex items-center gap-x-2">Calendar notification in</p>
              </template>
            </UiInputCheckbox>
            <span v-if="activity.calendar_checkbox" class="flex items-center gap-x-2">
              <UiInputTextField
                v-model="activity.calendar"
                name="calendar_delay"
                type="number"
                placeholder="15"
                compact
                class="max-w-[100px]"
                inline-error
                :error="useGetFieldErrors(v$, ['calendar'])"
              />
              minutes</span
            >
          </div>
        </div>
      </UiExpansionPanel>
    </template>
    <div class="border-t border-solid border-black-10"></div>
    <div class="flex flex-row items-center gap-4">
      <UiIcon name="user" class="text-black-70" />
      <UiInputTextField :model-value="user?.name" name="Name" disabled compact />
    </div>
    <div class="flex flex-row items-center gap-4">
      <UiIcon name="link" class="text-black-70" />
      <UiInputSelect
        v-model="activity.lead_id"
        :items="leads"
        placeholder="Lead's Name"
        name="lead-name"
        compact
        async
        :loading="retrieving"
        @updated:shallow-value="getActiveLeads"
        @update:model-value=";[updateTitle(), updateLanguage()]"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { endOfDay, getDay, addHours, startOfHour } from 'date-fns'
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email, requiredIf, minValue } from '@vuelidate/validators'
import {
  CALENDAR_ACTIVITY_TYPES,
  CALENDAR_ACTIVITY_TYPES_COLORS_MAP,
  PERMISSIONS,
  TOURS_IDS,
  SETTING_CODES,
} from '@/constants'
import type { Activity, DateRange, InputItem, InputItemLead, Lead, LibraryItem } from '@/types'
import { useAuthStore } from '~/store/auth'
import { TOURS } from '~/components/dynamic/maps'
import { useUiStore } from '~/store/ui'

const user = useUser()
const authStore = useAuthStore()
const uiStore = useUiStore()

const emits = defineEmits([
  'update:modelValue',
  'edited',
  'created',
  'update-event-on-calendar',
  'update-title-on-calendar',
])

type Props = {
  activityToEdit?: Activity
  lead?: Lead
  initialDate?: Date
  forAnotherUser?: boolean
  activityType?: string
}
const props = withDefaults(defineProps<Props>(), {
  activityToEdit: undefined,
  lead: undefined,
  initialDate: () => startOfHour(addHours(new Date(), 1)),
  activityType: undefined,
})

const route = useRoute()

const isSDRTourActive = computed(() => {
  return route.query.tour === 'sdr'
})

onNuxtReady(async () => {
  await Promise.all([getActiveLeads(), getCalendarTypes(), getReminders(), getTemplateReminder(), getUsers()])

  if (props.activityToEdit?.notifications?.length) {
    props.activityToEdit.notifications.forEach((n) => {
      if (n.hasOwnProperty('sms')) {
        activity.value.sms = n.sms
        activity.value.sms_checkbox = true
      }
      if (n.hasOwnProperty('popup')) {
        activity.value.calendar = n.popup
        activity.value.calendar_checkbox = true
      }
    })
  }
})

const eventTypes = ref<LibraryItem[]>([])
const leads = ref<InputItemLead[]>([])
const loading = ref(false)
const retrieving = ref(false)
const reminderPanel = ref(true)
const globalSmsEnabled = ref(false)
const globalCalendarEnabled = ref(false)
const isForAnotherUser = ref(props.forAnotherUser)
const usersItems = ref<InputItem[]>([])
const interactedWithReminders = ref(false)
const form = ref<HTMLElement>()
const highlightReminders = ref(false)
const templateLanguages = ref<InputItem[]>([])

const selectedLead = computed(() => leads.value.find((l: InputItemLead) => l.value === activity.value.lead_id))
const selectedLeadEmail = computed(() => selectedLead.value?.email)
const isAllDayType = computed(() => props.activityToEdit?.type?.code === CALENDAR_ACTIVITY_TYPES.ALL_DAY)
const allDay = ref(isAllDayType.value)

const mainCalendarTypes = computed(() =>
  eventTypes.value
    .filter((t: LibraryItem) => ![CALENDAR_ACTIVITY_TYPES.ALL_DAY, CALENDAR_ACTIVITY_TYPES.CALL].includes(t?.code))
    .reverse()
)

const activity = ref<Partial<Activity>>(
  props.activityToEdit
    ? {
        ...toRaw(props.activityToEdit),
        id: Number(props.activityToEdit.id),
        type_id: Number(props.activityToEdit.type?.id),
        lead_id: props.activityToEdit.lead?.id,
        user_id: Number(props.activityToEdit.user?.id),
        language_id: Number(props.activityToEdit.notifications?.[0]?.language_id),
      }
    : {
        id: 1,
        title: '',
        start: new Date(props.initialDate),
        end: new Date(addHours(props.initialDate, 1)),
        type_id: 0,
        type: eventTypes.value[0],
        user_id: Number(user?.id),
        comment: '',
        participants: [],
        lead_id: props.lead?.id,
        generate_meet: false,
        sms: undefined,
        calendar: undefined,
        sms_checkbox: false,
        calendar_checkbox: false,
        language_id: undefined,
      }
)

const leadLanguage = computed(
  () =>
    selectedLead.value?.language?.name || activity.value.lead?.language?.name || props.lead?.language?.name || 'English'
)

const isTaskType = computed(() => activity.value.type?.code === CALENDAR_ACTIVITY_TYPES.TASK)

const getCalendarTypes = async () => {
  eventTypes.value = await useCalendarActivityTypes()

  if (props.activityType) {
    updateType(eventTypes.value.find((t: LibraryItem) => t.code === props.activityType)!)
  }

  if (isAllDayType.value) {
    const taskType = eventTypes.value.find((t: LibraryItem) => t.code === CALENDAR_ACTIVITY_TYPES.TASK)
    activity.value.type_id = taskType?.id!
    activity.value.type = taskType
  }

  if (!activity.value.type_id) {
    activity.value.type_id = mainCalendarTypes.value[0].id
    activity.value.type = mainCalendarTypes.value[0]
  }

  if (!activity.value.title) {
    updateTitle()
  }
  uiStore.showTour({
    component: TOURS.ACTIVITY_CREATION_TYPES,
    id: TOURS_IDS.ACTIVITY_CREATION_TYPES,
  })
}

const getTemplateReminder = async () => {
  try {
    const data = await useGetTemplateSettingsByCode(SETTING_CODES.SMS_TEMPLATE_REMINDER)
    templateLanguages.value = data.template_data.translations.map((t: any) => ({
      value: t.language.id,
      text: t.language.name,
    }))
    if (!activity.value.language_id) {
      activity.value.language_id = getTemplateLanguage(props.lead?.language_id)
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getTemplateLanguage = (id?: number) => {
  return templateLanguages.value.find((t: InputItem) => t.value === id)?.value || templateLanguages.value[0].value
}

const getReminders = async () => {
  try {
    const codes = {
      code: [
        SETTING_CODES.CALENDAR_DELAY,
        SETTING_CODES.SMS_DELAY,
        SETTING_CODES.SMS_CHECKBOX_2N,
        SETTING_CODES.CALENDAR_CHECKBOX,
      ],
    }
    const settings: Record<string, string> = await useGetSettingGroupByCodes(codes)

    globalSmsEnabled.value = !!+settings[SETTING_CODES.SMS_CHECKBOX_2N]
    globalCalendarEnabled.value = !!+settings[SETTING_CODES.CALENDAR_CHECKBOX]

    if (!props.activityToEdit?.notifications?.length) {
      activity.value.calendar = settings[SETTING_CODES.CALENDAR_DELAY]
      activity.value.sms = settings[SETTING_CODES.SMS_DELAY]
    }

    if (props.activityToEdit) return

    if (globalSmsEnabled.value) {
      activity.value.sms_checkbox = true
    }
    if (globalCalendarEnabled.value) {
      activity.value.calendar_checkbox = true
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const updateTitle = () => {
  if (isSDRTourActive?.value) {
    return
  }

  activity.value.title = `${activity.value.type?.name} with ${selectedLead.value?.text || props.lead?.name || ''}`
  emits('update-event-on-calendar', activity.value.start)
}

const updateLanguage = () => {
  activity.value.language_id = getTemplateLanguage(selectedLead.value?.language.id)
}

const getActiveLeads = async (query?: string) => {
  retrieving.value = true
  try {
    const { data } = await useGetActiveLeads(query)
    leads.value = data.map((l: any) => ({ text: l.name, value: l.id, email: l.email, language: l.language }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    retrieving.value = false
  }
}

const getUsers = async () => {
  retrieving.value = true
  usersItems.value = await useGetAllUsersItems()
  retrieving.value = false
}

const selectedDate = (date: Date) => {
  activity.value.start = date
  activity.value.end = addHours(date, 1)
  // If the the calculations end up on the following day, set back to the end of the day
  if (getDay(activity.value.end) !== getDay(new Date(activity.value.start))) {
    activity.value.end = endOfDay(new Date(activity.value.start))
  }
}

const eventTypeComponent = {
  [CALENDAR_ACTIVITY_TYPES.MEETING]: resolveComponent('ActivityCreateMeeting'),
  [CALENDAR_ACTIVITY_TYPES.CALL]: resolveComponent('ActivityCreateCall'),
}

const currentComponent = computed(
  () =>
    eventTypeComponent[
      eventTypes.value.find((t: LibraryItem) => t.id === activity.value.type_id)
        ?.code as keyof typeof eventTypeComponent
    ]
)

const validateEmails = (emails: string[]) => {
  if (!emails.length) return true
  return emails.every((e) => email.$validator(e))
}

const rules = computed(() => ({
  title: { required: helpers.withMessage('The name is required', required) },
  start: { required: helpers.withMessage('The start date is required', required) },
  end: { required: helpers.withMessage('The end date is required', required) },
  type_id: { required: helpers.withMessage('The type is required', required) },
  participants: { validateEmails: helpers.withMessage('The guests emails must be valid', validateEmails) },
  calendar: {
    required: helpers.withMessage('This field is required', requiredIf(activity.value.calendar_checkbox)),
    minValue: minValue(1),
  },
  sms: {
    required: helpers.withMessage('This field is required', requiredIf(activity.value.sms_checkbox)),
    minValue: minValue(1),
  },
  language_id: {
    required: helpers.withMessage(
      'The language is required',
      requiredIf(activity.value.sms_checkbox! && !isTaskType.value)
    ),
  },
}))

const v$ = useVuelidate(rules, activity)

const updateActivityTime = (event: DateRange) => {
  activity.value.start = event.start
  activity.value.end = event.end
  emits('update-event-on-calendar', activity.value.start)
}

const updateType = (eventType: LibraryItem) => {
  activity.value.type_id = eventType.id
  activity.value.type = eventType
  updateTitle()
  allDay.value = false
}

const submit = async () => {
  if (isTaskType.value) {
    if (allDay.value) {
      activity.value.type_id = eventTypes.value.find((t: LibraryItem) => t.code === CALENDAR_ACTIVITY_TYPES.ALL_DAY)?.id
      activity.value.start = new Date(activity.value.start.setHours(0, 0))
      activity.value.end = endOfDay(new Date(activity.value.end))
    }
    if (activity.value.sms_checkbox) {
      activity.value.sms_checkbox = false
      activity.value.language_id = undefined
    }
    activity.value.participants = []
  }
  if (activity.value.type?.code !== CALENDAR_ACTIVITY_TYPES.MEETING) delete activity.value.location

  activity.value.notifications = []
  if (activity.value.calendar_checkbox || activity.value.sms_checkbox) {
    if (activity.value.calendar_checkbox) activity.value.notifications.push({ popup: +activity.value.calendar! })
    if (activity.value.sms_checkbox)
      activity.value.notifications.push({ sms: +activity.value.sms!, language_id: activity.value.language_id })
  }

  if (!(await v$.value.$validate())) return

  if (!remindersWereTouched()) return

  loading.value = true
  const cleanActivity = omitBy(toRaw(activity.value), (f) => f === '' || f === null) as Activity
  try {
    if (props.activityToEdit) {
      const activityUpdated = await useUpdateCalendarActivity(cleanActivity)
      emits('edited', activityUpdated)
    } else {
      const activityCreated = await useCreateCalendarActivity(cleanActivity)
      emits('created', activityCreated)
    }
  } catch (err: any) {
    uiStore.showSnackBanner(err.message, 'error')
  } finally {
    loading.value = false
  }
}

const remindersWereTouched = () => {
  const isMeetingAndRemindersNotInteracted =
    activity.value.type?.code === CALENDAR_ACTIVITY_TYPES.MEETING && !interactedWithReminders.value
  if (isMeetingAndRemindersNotInteracted && authStore.getIsAgent) {
    reminderPanel.value = true
    interactedWithReminders.value = true
    highlightReminders.value = true
    form.value!.scrollIntoView({ behavior: 'smooth', block: 'end' })
    return false
  } else {
    return true
  }
}

const updateUser = () => {
  if (!isForAnotherUser.value) {
    activity.value.user_id = Number(user?.id)
  } else {
    activity.value.user_id = undefined
  }
}

const addUserObject = () => {
  const user = usersItems.value.find((a) => a.value === activity.value.user_id)
  if (user) activity.value.user = { id: user.value, name: user.text }
  nextTick(() => {
    updateTitle()
  })
}

const addLeadEmail = () => {
  activity.value.participants = selectedLeadEmail.value ? [selectedLeadEmail.value] : []
}

const agentSelect = ref(false)

const isAgentSelectInActivityVisible = useState('isAgentSelectInActivityVisible', () => false)

watchEffect(() => {
  if (agentSelect.value) {
    isAgentSelectInActivityVisible.value = true
  }
})

defineExpose({
  loading,
  submit,
  activity,
  selectedDate,
})
</script>

<style scoped></style>
